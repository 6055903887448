import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    th: {
        fontWeight: 700
    }
});
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(event, date) {
    return { event, date };
}

const rows = [
	createData('Issue of Application Form begins(Online only)', '- 05-07-2024'),
    createData('Last date for submission of application forms(Online)', '- 15-07-2024'),
    createData('Last date to notify any error in the application', '- 18-07-2024'),
    createData('Publication of Merit List', '- 19-07-2024'),
    createData('Date of Online admission(First Allotment).', '- 22-07-2024'),    
];

export default function Dates() {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className={classes.th} >Events</StyledTableCell>
                            <StyledTableCell className={classes.th} >Dates</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.event}>
                                <TableCell component="th" scope="row">
                                    {row.event}
                                </TableCell>
                                <TableCell >{row.date}</TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
					
                </Table>
				{/* <span className="ml-2 italic text-xs font-semibold">✝ Errors, if any, should be mailed to admissions@rajagiritech.edu.in with the name and application number of the applicant</span> */}
            </TableContainer>
		
        </Grid>
    );
}
