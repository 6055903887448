import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Btn from '../Components/Button'
import Icon from '@material-ui/core/Icon';
import './style.css'
import VjcetService from '../vjcet-service';
import Joi from 'joi-browser';
import Header from '../Components/Header'
import { Container } from '@material-ui/core';
import Footer from '../Components/Footer';
import Contact from '../images/icons/contact.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';



const months_array = VjcetService.get_months_array();
const quali_boards_array = VjcetService.get_quali_boards_array();
const quali_exams_array = VjcetService.get_quali_exams_array();
const courses_array = VjcetService.get_courses_array();
const religion_array = VjcetService.get_religion_array();
const gender_array = VjcetService.get_gender_array();
const quota_labels = VjcetService.get_quota_array();

export default class ApplicationStatus extends Component {
    state = {
        account: { dob: '', application_no: '', },
        errors: {},
        disable_btn: false,
        loginErr: "",
        basicData: {
            first_name: '', middle_name: '', last_name: '', dob: '', gender: '', community: "",
            applicant_email: '', application_no: "",
            applicant_mobile: '', title: "mr", id: this.props.match.params.a_id, photo: null,
            religion_id: '', sub_quota_id: "", application_id: "", quota_id: 0, photo_url: "", photo_error: "",
            vjcet_admission_email: 'admission@vjcet.org', vjcet_admission_tel: '8281352211', stage: 1,
            ranking_1: -1, ranking_2: -1
        },
        guardian: {
            father_name: '', father_occupation: '', father_mobile: '', mother_name: '', mother_occupation: '',
            mother_mobile: '', guardian_name: '', guardian_relationship: ' ', guardian_mobile: '', guardian_address_1: '',
            guardian_address_2: '', guardian_address_city: '', guardian_address_district: '', guardian_address_pincode: '',
            guardian_address_state: '', annual_income: ''
        },
        education: {
            qualification_exam: 0, qualification_month: 0, qualification_year: 0, qualification_board: 0, qualification_registration_no: '',
            physics_score: '', chemistry_score: '', maths_score: '', keam_year: 2024, keam_application_no: '', keam_roll_no: '', paper_1_score: "", paper_2_score: "",
            keam_total_score: 0.0000, school_name: '', school_district: ''
        },
        courses: { first_option: -1, second_option: -1, third_option: -1, fourth_option: -1, fifth_option: '', sixth_option: '' },
        status_auth: false,
    }
    schema = {
        email: Joi.string().required().label("Email"),
        password: Joi.string().required().label("Password"),

    }
    handleChange = ({ currentTarget: input }) => {

        const errors = { ...this.state.errors };
        const errorMessage = "";

        if (errorMessage) {
            errors[input.name] = errorMessage;
        } else {
            delete errors[input.name];
        }

        const account = { ...this.state.account };
        account[input.name] = input.value;
        this.setState({ account, errors });

    }
    handleSubmit = async (e) => {
        e.preventDefault();
        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        this.setState({
            loginErr: "",
        });

        let obj = {
            dob: this.state.account.dob,
            application_no: this.state.account.application_no
        }

        this.setState({ disable_btn: true });
        VjcetService.application_status(obj).then((resp) => {

            this.setState({ disable_btn: false });

            if (resp && resp !== undefined) {
                const data = resp.data;
                const basicData = { ...this.state.basicData };
                const guardian = { ...this.state.guardian };
                const education = { ...this.state.education };
                const courses = { ...this.state.courses };


                if (data.success) {
                    this.state.status_auth = true;
                    let b_data = {};


                    let photo = data.info.photo || "";
                    if (photo.length > 0) {
                        photo = data.url + photo;
                    }

                    basicData.photo_url = photo;
                    basicData.application_no = data.info.application_no || "";
                    basicData.quota_id = data.info.quota_id || -1;
                    basicData.first_name = data.info.first_name || "";
                    basicData.middle_name = data.info.middle_name || "";
                    basicData.last_name = data.info.last_name || "";
                    basicData.dob = data.info.dob || "";
                    basicData.gender = data.info.gender || -1;
                    basicData.religion_id = data.info.religion_id || "";
                    basicData.applicant_mobile = data.info.applicant_mobile || "";
                    guardian.mother_name = data.info.mother_name || "";
                    guardian.father_name = data.info.father_name || "";
                    guardian.father_mobile = data.info.father_mobile || "";
                    guardian.mother_mobile = data.info.mother_mobile || "";
                    basicData.community = data.info.community || "";
                    basicData.ranking_1 = data.info.ranking1 || -1;
                    basicData.ranking_2 = data.info.ranking2 || -1;



                    if (data.info.qualification && data.info.qualification !== undefined) {

                        let p1_score = (data.info.qualification.paper_1_score && data.info.qualification.paper_1_score !== undefined) ? (data.info.qualification.paper_1_score).toFixed(4) : "";
                        let p2_score = (data.info.qualification.paper_2_score && data.info.qualification.paper_2_score !== undefined) ? (data.info.qualification.paper_2_score).toFixed(4) : "";
                        let total_score = (data.info.qualification.total_keam && data.info.qualification.total_keam !== undefined) ? (data.info.qualification.total_keam).toFixed(4) : "";

                        education.physics_score = data.info.qualification.physics_score || "NOT SPECIFIED";
                        education.maths_score = data.info.qualification.maths_score || "NOT SPECIFIED";
                        education.chemistry_score = data.info.qualification.chemistry_score || "NOT SPECIFIED";
                        education.qualification_registration_no = data.info.qualification.registration_no || "NOT SPECIFIED";
                        education.qualification_exam = data.info.qualification.qualification_exam || -1;
                        education.qualification_month = data.info.qualification.month || "NOT SPECIFIED";
                        education.qualification_year = data.info.qualification.year || "NOT SPECIFIED";
                        education.qualification_board = data.info.qualification.board || -1;
                        education.keam_application_no = data.info.qualification.keam_application_no || "NOT SPECIFIED";
                        education.keam_roll_no = data.info.qualification.keam_roll_no || "NOT SPECIFIED";
                        education.paper_1_score = p1_score;
                        education.paper_2_score = p2_score;
                        education.keam_total_score = total_score;
                    }

                    if (data.info.options && data.info.options !== undefined) {

                        data.info.options.forEach(option => {

                            switch (option.preference_order) {


                                case 1:
                                    courses["first_option"] = option.course_id || -1;
                                    break;

                                case 2:
                                    courses["second_option"] = option.course_id || -1;
                                    break;

                                case 3:
                                    courses["third_option"] = option.course_id || -1;
                                    break;

                                case 4:
                                    courses["fourth_option"] = option.course_id || -1;
                                    break;


                                default:
                                    break;
                            }

                        });
                    }

                    this.setState({ basicData });
                    this.setState({ guardian });
                    this.setState({ education });
                    this.setState({ courses });

                } else if (!data.success && data.code === 200) {
                    this.setState({
                        loginErr: data.info,
                    });
                }

            } else {
                this.setState({
                    loginErr: "Please enter valid details",
                });
            }

        });
    }

    render() {
        const { account, errors, basicData, guardian } = this.state;
        return (
            <div>
                <Header />
                <Container className="margin-b" style={{ minHeight: "50vh" }}>
                    <div>
                        <h4 style={{ marginBottom: "2vh", marginTop: "2vh" }}>
                            Please enter a valid Date of Birth and Application number to view your Rank.
                        </h4>
                        <form onSubmit={this.handleSubmit} id="status-form">
                            <div className="form-fields">
                                <TextField
                                    id="dob"
                                    type="date"
                                    name="dob"
                                    value={account.dob}
                                    onChange={this.handleChange}
                                    format={'DD-MM-YYYY'}
                                    label="Date of Birth"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">calendar_today</Icon>
                                            </InputAdornment>
                                        ),
                                        inputProps: { max: "2010-12-31" }
                                    }}
                                />
                            </div>
                            <div className="form-fields">
                                <TextField
                                    label="Application no"
                                    onChange={this.handleChange}
                                    name="application_no"
                                    id="application_no"
                                    value={account.application_no}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon-style">person</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {errors && <div className="error-text">{errors.application_no}</div>}
                                {this.state.loginErr && <div className="error-text">{this.state.loginErr}</div>}
                            </div>
                            <div className="btn-style" style={{ marginTop: "0vh", justifyContent: "flex-start" }}>
                                <Btn disabled={this.state.disable_btn} type="submit" title="Submit" value="submit" id="login-btn" />
                            </div>
                        </form>
                    </div>
                    {this.state.disable_btn && <LinearProgress />}
                    {this.state.status_auth ? (<div className="summary_container">
                        <div className="summaryHeader">
                            <div>
                                <p className="text-style1" style={{ marginBottom: "5px" }}>
                                    Your Application Number is :
                                <span className="highlight"> {basicData.application_no}</span>
                                </p>
                                <p className="text-style1" style={{ marginBottom: "5px" }}>
                                    Application under :
                                    <span className="highlight">
                                        {(quota_labels[basicData.quota_id] && quota_labels[basicData.quota_id] !== undefined) ?
                                            quota_labels[basicData.quota_id] : "N/A"
                                        } Quota
                                </span>
                                </p>
                                {/* <Btn title='Download PDF' click={this.download_application} /> */}

                                {(VjcetService.QUOTA_MANAGEMENT && (basicData.ranking_1 > 0 || basicData.ranking_2 > 0)) ? (
                                    <div className="ranklist_title highlight">Provisional Rank</div>
                                ) : ""}
                                {(basicData.quota_id === VjcetService.QUOTA_MANAGEMENT && basicData.ranking_1 > 0) ? (
                                    <div className="ranklist_container">
                                        <div>Open Merit</div>
                                        <div>{basicData.ranking_1}</div>
                                    </div>
                                ) : ""}
                                {(basicData.quota_id === VjcetService.QUOTA_MANAGEMENT && basicData.ranking_2 > 0) ? (
                                    <div className="ranklist_container">
                                        <div>Christian Merit</div>
                                        <div>{basicData.ranking_2}</div>
                                    </div>
                                ) : ""}
                            </div>
                            <div>
                                {basicData.photo_url ? (
                                    <img style={{ width: 150, height: 150, marginBottom: "10px", }} alt="profile" src={basicData.photo_url} />
                                ) : (
                                        <Skeleton variant="rect" width={150} height={150} />
                                    )}
                            </div>
                        </div>
                        <div id="basic-data-summary" className="summary-style">
                            <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} /> Personal Information</h4>
                            <ul className="summary-list-style">
                                <li>Applicant Name</li>
                                <li>
                                    <span className="text-upper">{basicData.first_name} {basicData.middle_name} {basicData.last_name}</span>

                                </li>
                                <li>Parent Name</li>
                                <li>{guardian.father_name ? guardian.father_name : guardian.mother_name}</li>
                                <li>Date of Birth</li>
                                <li>
                                    {(basicData.dob && basicData.dob !== undefined) ?
                                        new Intl.DateTimeFormat("en-In").format(new Date(basicData.dob)) : "N/A"
                                    }
                                </li>
                                <li>Gender</li>
                                <li className="text-upper">
                                    {(gender_array[basicData.gender] && gender_array[basicData.gender] !== undefined) ?
                                        gender_array[basicData.gender] : "N/A"
                                    }
                                </li>
                                <li>Parent Mobile</li>
                                <li>{guardian.father_mobile ? guardian.father_mobile : guardian.mother_mobile}</li>
                                <li>Applicant Mobile</li>
                                <li>{basicData.applicant_mobile}</li>

                                <li>Religion</li>
                                <li className="text-upper">
                                    {(religion_array[basicData.religion_id] && religion_array[basicData.religion_id] !== undefined) ?
                                        religion_array[basicData.religion_id] : "N/A"
                                    }
                                </li>
                                <li>Community</li>
                                <li className="text-upper">
                                    {(basicData.community && basicData.community !== undefined) ?
                                        basicData.community : "Not Specified"
                                    }
                                </li>
                            </ul>
                        </div>
                        <div id="qualifying-exam-summary" className="summary-style">

                            <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />
                                    Details of Qualifying Examination
                                </h4>
                            <ul className="summary-list-style">
                                <li>Name of the qulifying exam</li>
                                <li>
                                    {(quali_exams_array[this.state.education.qualification_exam] && quali_exams_array[this.state.education.qualification_exam] !== undefined) ?
                                        quali_exams_array[this.state.education.qualification_exam] : "N/A"
                                    }
                                </li>
                                <li>Month and year of pass</li>
                                <li>{this.state.education.qualification_month}/{this.state.education.qualification_year}</li>
                                <li>Univeristy/Board</li>
                                <li>
                                    {(quali_boards_array[this.state.education.qualification_board] && quali_boards_array[this.state.education.qualification_board] !== undefined) ?
                                        quali_boards_array[this.state.education.qualification_board] : "N/A"
                                    }
                                </li>
                                <li>Register Number</li>
                                <li className="text-upper">
                                    {this.state.education.qualification_registration_no}
                                </li>
                            </ul>
                            <div>
                                <h4 className="sub-title">Marks</h4>
                                <ul className="summary-list-style">
                                    <li>Maths</li>
                                    <li>{this.state.education.maths_score}</li>
                                    <li>Physics</li>
                                    <li>{this.state.education.physics_score}</li>
                                    <li>Chemistry/Equivalent</li>
                                    <li>{this.state.education.chemistry_score}</li>
                                </ul>
                            </div>

                        </div>
                        <div id="entrance-exam-summary" className="summary-style">
                            <h4><img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />
                                Common Entrance 2024 details
                            </h4>
                            <ul className="summary-list-style">
                                <li>Roll No.</li>
                                <li>
                                    {this.state.education.keam_roll_no}
                                </li>
                                <li>Application No</li>
                                <li>{this.state.education.keam_application_no}</li>
                                <li>KEAM Normalized Score</li>
                                <li>
                                    {this.state.education.paper_1_score}
                                </li>
                                {/*<li>Paper II - Marks</li>
                                <li className="text-upper">
                                    {this.state.education.paper_2_score}
                                </li>*/}
                                {/*<li>Total Marks</li>
                                <li className="text-upper">
                                    {this.state.education.keam_total_score}
                                </li>*/}
                            </ul>
                        </div>
                        <div id="options-summary" className="summary-style">
                            <h4>
                                <img src={Contact} alt="logo" style={{ width: "50px", paddingRight: "5px" }} />
                                Options During Allotment
                            </h4>
                            <ul className="summary-list-style">
                                <li>Option 1</li>
                                <li className="text-upper">
                                    {(courses_array[this.state.courses.first_option] && courses_array[this.state.courses.first_option] !== undefined) ?
                                        courses_array[this.state.courses.first_option] : "N/A"
                                    }
                                </li>
                                <li>Option 2</li>
                                <li className="text-upper">
                                    {(courses_array[this.state.courses.second_option] && courses_array[this.state.courses.second_option] !== undefined) ?
                                        courses_array[this.state.courses.second_option] : "N/A"
                                    }
                                </li>
                                <li>Option 3</li>
                                <li className="text-upper">
                                    {(courses_array[this.state.courses.third_option] && courses_array[this.state.courses.third_option] !== undefined) ?
                                        courses_array[this.state.courses.third_option] : "N/A"
                                    }
                                </li>
                                <li>Option 4</li>
                                <li className="text-upper">
                                    {(courses_array[this.state.courses.fourth_option] && courses_array[this.state.courses.fourth_option] !== undefined) ?
                                        courses_array[this.state.courses.fourth_option] : "N/A"
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>

                    ) : ''}

                    <ul className="list-style highlight">
					{/*<li>Initial phase Allotment(Online) to Management quota seats will commence on Wednesday, 14-06-2024 at 10 AM. Allotment status will be available in your login.</li>*/}
					
                        <li>For admission related queries, please contact: </li>
						
                        <li >Manu Jose 9605760293, Babu T Chacko 9656154572, Lins Paul 9847101711</li>
                       
                    </ul>
                </Container>
                <Footer />
            </div>
        )
    }
}
