import React, { useState, useEffect } from 'react';
import Btn from '../Button';
import logo from '../../images/logos/logo.png';
import { useHistory, Link } from 'react-router-dom';
import VjcetService from '../../vjcet-service';
import Login from '../Login';
import Signup from '../Signup';
import Instructions from '../OnlineInstructions';
import './style.css';
import Code from '../FloatCode';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

const Header = (props) => {
	let history = useHistory();

	const [loggedIn, setLoggedIn] = useState(false);
	const [applicationId, setapplicationId] = useState(-1);
	const [isHomePage, setHomePage] = useState(true);
	const form = props.form;

	useEffect(() => {
		let is_authenticated = VjcetService.is_authenticated();
		setLoggedIn(is_authenticated);
		setapplicationId(VjcetService.get_application_id());
		let is_home_page = window.location.pathname === '/' ? true : false;
		setHomePage(is_home_page);
	}, []);

	const logout = () => {
		VjcetService.sign_out()
			.then((resp) => {
				let data = resp.data;
				if (data.success) {
					VjcetService.remove_local_storage('access_token');
					VjcetService.remove_local_storage('a_id');
					setLoggedIn(false);
					history.push('/');
				} else {
					console.log('error signing out');
				}
			})
			.catch(function (error) {
				VjcetService.remove_local_storage('access_token');
				VjcetService.remove_local_storage('a_id');
				history.push('/');
				console.log('error signing out');
			});
	};

	const go_to_form = () => {
		history.push('/form/' + applicationId);
	};

	const go_to_status = () => {
		history.push('/status');
	};

	const timeline = () => {
		history.push('/timeline');
	};

	return (
		<div id="reg-header" className={props.name} style={paddingStyle}>
			<div id="header" style={headerBgColor}>
				<Link className="logo" to="/">
					<img src={logo} alt="logo" />
				</Link>
				{form ? '' : <Code />}
				<div className="link-form">
					{/* {loggedIn ? (
						''
					) : isHomePage ? (
						<a href="/register-interest" style={linkStyle}>
							<button
								className="rounded-lg font-bold text-white p-2 text-base border-2  border-white"
								shadow="none"
							>
								B. Tech Admission 2024 - Register Interest
							</button>
						</a>
					) : (
						''
					)} */}
		
					{/* Apply Now button */}
					  { 
						loggedIn
						? ""
						: (isHomePage
							? <a href="#reg-form-home" style={linkStyle} >
								<Btn title="Apply now" shadow="none" /></a>
							: <Signup />
						)
					}   
			
					<Instructions /> 
			

				
				    {!loggedIn ? <Btn click={go_to_status} title="View your Rank" /> : ""}  {/*without login rank check */}
					{loggedIn ? <Btn click={go_to_status} title="View your Rank" /> : ""}  {/*after login rank check 
				*/}
				
				  {loggedIn ? <Btn click={go_to_form} title="Form" shadow="none" /> : ""}  
					{loggedIn ? <Btn click={timeline} title="Timeline" shadow="none" /> : ""}   
					{loggedIn ? <Btn click={logout} title="Logout" /> : <Login history={history} />}  				

				</div>
			</div>
		</div>
	);
};

const headerBgColor = {
	background: '#9d0055',
	padding: '20px',
	marginTop: '0px',
	marginBottom: '20px',
};
const paddingStyle = {
	paddingLeft: '0px',
	paddingRight: '0px',
};
const linkStyle = {
	textDecoration: 'none',
	display: 'inline',
};


export default Header;
