import React from 'react';
import { Container, Link } from '@material-ui/core';
import Btn from '../Components/Button';
import logo from '../images/logoVJCET.png';
import showcase_img from '../images/form.png';
import campus from '../images/vjcet-campus.jpg';
import students from '../images/vjcet1.png';
import center_img from '../images/vjcet2.png';
import right_img from '../images/vjcet3.jpg';
import building from '../images/vjcet-building.jpeg';

import './style.css';
import Header from '../Components/Header';
import VjcetService from '../vjcet-service';

const Introduction = () => {
	return (
		<Container>
			<section className="text-gray-600 body-font">		
			{/* scroll new start */}
				<div class="ticker-wrapper-h">
					<div class="heading">Announcements</div>
					
					<ul class="news-ticker-h">
						<li><a style={{color:"red"}}>VJCET stands at 14th position among all Colleges under KTU for the 2020-2024 Batch University result & 4th among Self financing colleges</a></li>
						{/*<li><a href="">Why do we use it?</a></li>*/}					
					</ul>
				</div>
			{/* scroll new end */}

				<div className="container px-5 py-6 mx-auto align-middle">
					<div className="flex flex-col">
						<div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 ">

							{/*<div className="p-4 md:w-1/2 sm:mb-0 mb-6 text-center">
								<a style={downloadStyle}  href="/Managment Quota.pdf" target="_blank">
									MANAGEMENT ADMISSION SCHEDULE</a> 
							</div>*/}	
						
							{/*<div className="p-4 md:w-1/3 sm:mb-0 mb-6"> </div>*/}
							{/*<div className="p-4 md:w-1/2 sm:mb-0 mb-6 text-center">
								<a style={downloadStyle}  href="/Govt. Quota Admission Schedule 2024.pdf" target="_blank">
									GOVERNMENT ADMISSION SCHEDULE</a> 
							</div>	*/}				
						
					</div>
						<br></br>					
						<div className="flex flex-wrap flex-col">
							
															
							<h1 className="text-center text-gray-900 font-extrabold title-font text-4xl my-2">
								{VjcetService.ACADEMIC_YEAR_TITLE}
							</h1>
							<p className="leading-relaxed text-base pl-0 pb-10 ">
								50% of the total seats (ie, 30 in a batch of 60 students) are allotted through the
								common counseling by the Entrance Commissioner of Kerala. From the balance 50%, under
								management quota, 35% seats (ie, 21 in a batch of 60 students) are allotted based on
								merit in various quota (Open Quota – 10 Seats and Christian Quota – 11 Seats) and 15% of
								the seats (ie, 9 in a batch of 60 students) will be filled as NRI quota. Management
								Quota Application can be submitted only through Online.
							</p>
						</div>
					</div>
					<div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
						<div className="p-4 md:w-1/3 sm:mb-0 mb-6">
							<div className="rounded-lg h-64 overflow-hidden">
								<img
									alt="content"
									className="object-cover object-center h-full w-full showcase"
									src={students}
								/>
							</div>
						</div>
						<div className="p-4 md:w-1/3 sm:mb-0 mb-6">
							<div className="rounded-lg h-64 overflow-hidden">
								<img
									alt="content"
									className="object-cover object-center h-full w-full showcase"
									src={center_img}
								/>
							</div>
						</div>
						<div className="p-4 md:w-1/3 sm:mb-0 mb-6">
							<div className="rounded-lg h-64 overflow-hidden">
								<img
									alt="content"
									className="object-cover object-center h-full w-full showcase"
									src={right_img}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Container>
	);
};

const downloadStyle = {
	textDecoration: 'none',
	display: 'inline',
	color: 'white',
	fontSize: '14px',
	fontWeight: '700',
	borderRadius: "30px",
	marginLeft: "15px",
	boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
	height: "150px",
	backgroundColor: "#9d0055",
	padding: "15px",
	

}
export default Introduction;
